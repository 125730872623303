<template>
    <div>
        <button
        class="btn btn-dark"
        @click="modalAdProduct = true">
            Agregar
        </button>
        <b-modal v-model="modalAdProduct" title="Agregar Producto/Servicio" size="lg" hide-footer>
            <form>
                <div class="row form-group">
                    <label class="col-sm-2 col-lg-1 col-form-label" style="text-align: right;">Tipo : </label>
                    <div class="col-sm-6 col-lg-7">
                        <multiselect
                            v-model="productoSelect"
                            track-by="nombre"
                            label="nombre"
                            placeholder="Seleccione Tipo"
                            :options="productos"
                            :allow-empty="false"
                            :show-labels="false">
                            <template slot="singleLabel" slot-scope="props">
                                {{ props.option.nombre }}
                            </template>
                            <template slot="option" slot-scope="props">
                                {{ props.option.codigo }} - {{ props.option.nombre }} -
                                <span style="color: red; font-weight: bold">
                                    {{ props.option.sub_grupo }}
                                </span>
                            </template>
                        </multiselect>
                    </div>
                    <label class="col-sm-3 col-lg-2 col-form-label">Cod. Producto : </label>
                     <div class="col-sm-2 col-lg-2">
                        <input type="text"
                        class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <label class="col-sm-4 col-lg-1 col-form-label" style="align: right;">Descripcion : </label>
                    <div class="col-sm-6 col-lg-7">
                       <input type="text" class="form-control">
                    </div>
                   <div class="col-sm-2 col-lg-2">
                        <input type="button" 
                        value="Almacenar"
                        class="btn btn-warning" >
                    </div>
                        <div class="col-sm-2 col-lg-1">
                        <input type="button" 
                        value="Listar"
                        class="btn" >
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-12">
                                <table class="table table-bordered-condensed mt-2" width="100%">
                                    <thead>
                                        <tr class="text-center">
                                            <th width="35%">Unidad Medida</th>
                                            <th width="20%">Cantidad</th>
                                            <th width="20%">Precio Unitario</th>
                                            <th width="20%">Descuento</th>
                                            <th width="20%">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                 <input
                                                    type="text"
                                                    class="form-control is-valid"
                                                    style="width: 130px; heigth: 10px"
                                                >
                                            </th>
                                            <td>
                                                <input type="text"
                                                class="form-control" style="width: 110px; heigth: 5px">
                                            </td>
                                            <td> 
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    style="width: 110px; heigth: 5px"
                                                >
                                            </td>
                                            <th>
                                                 <input
                                                    type="text"
                                                    class="form-control" 
                                                    style="width: 110px; heigth: 5px"                                            
                                                >
                                            </th>
                                            <th>
                                                 <input
                                                    type="text"
                                                    class="form-control"
                                                    style="width: 120px; heigth: 10px"
                                                    :disabled="true"
                                                >
                                            </th>
                                        </tr>
                                    </tbody>
                                </table> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="row mb-2">
                        <div class="col-sm-2 col-lg-2" style="align: left;">
                            <input type="text" class="form-control" value="N°IMEI">
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <input type="text" 
                            value="N°Serie"
                            class="form-control" >
                        </div>
                        <div class="col-sm-2 col-lg-2"></div>
                        <div class="col-sm-2 col-lg-2"></div>
                         <div class="col-sm-2 col-lg-2">
                            <button type="button" class="btn btn-secondary mr-1"
                                @click="modalAdProduct = false">
                                Cerrar
                            </button>
                        </div>
                        <div class="col-sm-2 col-lg-2">
                            <button type="submit" class="btn th-custom-color mr-4">Agregar</button>
                        </div>
                    </div>
                    <!-- <div class="row mb-2">
                        <div class="col-sm-4">                               
                                <div class="col-sm-12 text-left">
                                    <button type="button" class="btn btn-secondary mr-1"
                                            @click="modalAdProduct = false">Cancelar</button>
                                    <button type="submit" class="btn th-custom-color mr-4">Agregar</button>
                                </div>                          
                        </div>
                    </div> -->
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'


export default {
    name: 'theModalAddProduct',
    components: {
        Multiselect,
    },
    data() {
        return {
            modalAdProduct: false,
            productoSelect: null,
            productos: [],
            precio: null,
            precios: [],
            logotipo: null,
        };
    },
    methods: {
        onlyNumber(event) {
            let tecla = (document.all) ? event.keyCode : event.which;
            if (tecla < 13) {
                return true;
            }
            if (tecla === 13) {
                return event.preventDefault();
            }
            return this.isNumber(String.fromCharCode(tecla)) ? true : event.preventDefault();
        },
        isNumber(cantidad) {
            let expresion = /^\d+$/;
            return expresion.test(cantidad);
        },
    },
}
</script>