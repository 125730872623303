<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-4">
                <multiselect
                    v-model="tipoPersona"
                    track-by="nombre"
                    label="nombre"
                    placeholder="Cliente"
                    :searchable="true"
                    :options="tipoPersonas"
                    @input="loadPersons()"
                    :allow-empty="false">
                </multiselect>
            </div>
            <div class="col-sm-7">
                <multiselect
                    v-model="persona"
                    placeholder="Seleccionar"
                    :options="personas"
                    :allow-empty="false"
                    @input="setPerson()">
                    <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.nombre_comercial }}
                    </template>
                </multiselect>
            </div>
            <template>
                <!-- <div class="col-sm-1 pl-0">
                    <button class="btn btn-success"
                    type="button" @click="setAnticipo">Anticipo</button>
                </div> -->
                <div class="col-sm-1 pl-0">
                    <!-- <button type="button" class="btn btn-success" @click="modal.show = true">
                        <i class="nav-icon i-Add-User"></i>
                    </button> -->
                     <TheModalAddUser/>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

import TheModalAddUser from '../components/TheModalAddUser.vue';

export default {
    name: 'TheModalPersona',
    props: {
        tipoPersonas: Array,
        edit: {
            default: false
        },
        placeholder: {
            default: null
        },
        btnAddPerson: {
            default: true,
        },
        origen: {
            type: String,
            default: '',
        },
    },
    components: {
        Multiselect,
         TheModalAddUser,
    },
    data() {
        return {
            tipoPersona: null,
            persona: null,
            modal: {
                title: "Nuevo",
                show: false,
            },
            personas: [],
        };
    },
    methods: {
        loadPersons() {},
        setPerson() {},
        setAnticipo() {},
    },
}
</script>