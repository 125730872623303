<template>
    <div>
        <!-- <button
        class="btn btn-dark"
        @click="modalEditUser = true">
            Agregar
        </button> -->
        
                    <button type="button" class="btn" @click="modalEditUser = true">
                        <i class="nav-icon i-Add-User"></i>
                    </button>
        <b-modal v-model="modalEditUser" title="Modificar Cliente : N° 1"  size="lg" hide-footer>
            <form>
                <div class="row form-group">
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label class="col-form-label">Razon Social:</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text" class="form-control" autocomplete="off" placeholder="Razon Social">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label class="col-form-label">Pais:</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text"
                                class="form-control"
                                autocomplete="off"
                                placeholder="Pais"
                                >
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Mail:</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="Mail" 
                                >
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Direccion:</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="Ingrese su Direccion" 
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Nit:</label>
                            </div>
                            <div class="col-sm-4">
                                <input type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="Nit"
                                >
                            </div>
                            <div class="col-sm-4">
                                <input type="text" class="form-control pl-1 pr-2">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Ciudad:</label>
                            </div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" placeholder="Ciudad">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Celular:</label>
                                <label>     </label>
                            </div>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" aria-label="Celular">
                            </div>
                            <div class="col-sm-4">
                                <input type="text" class="form-control pl-1 pr-2">
                            </div>            
                        </div>
                    </div>
                    
                    
                </div>
                <div class="row form-group">
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap; color: orange;" class="col-form-label">Nombre de Factura</label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-6">
                        <div class="row mb-4">
                            <div class="col-sm-8 pr-0 text-right">
                                <button type="submit" class="btn th-custom-color mr-4">Guardar</button>
                                <button type="button" class="btn btn-secondary mr-1"
                                @click="modalEditUser = false">Cancelar</button>
                               
                            </div>
                        </div>
                    </div> -->
                </div>
                 <div class="row form-group">
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                
                            </div>
                            <div class="col-sm-8">
                                <button type="submit" class="btn th-custom-color mr-4">Guardar</button>
                                <button type="button" class="btn btn-secondary mr-4"
                                @click="modalEditUser = false">Cancelar</button>
                               
                            </div>
                        </div>
                    </div>
                 </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
//import Multiselect from 'vue-multiselect'
//

export default {
    name: 'TheModalEditUser',
    components: {
    },
    data() {
        return {
            modalEditUser: false,
        };
    },
    methods: {
        onlyNumber(event) {
            let tecla = (document.all) ? event.keyCode : event.which;
            if (tecla < 13) {
                return true;
            }
            if (tecla === 13) {
                return event.preventDefault();
            }
            return this.isNumber(String.fromCharCode(tecla)) ? true : event.preventDefault();
        },
        isNumber(cantidad) {
            let expresion = /^\d+$/;
            return expresion.test(cantidad);
        },
    },
}
</script>