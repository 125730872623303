<template>
    <div>   
        <button type="button" class="btn btn-danger" @click="modalPago = true">
            Forma de Pago
        </button>
        <b-modal v-model="modalPago" title="Forma de Pago"  size="lg" hide-footer class="modal-dialog modal-lg" role="dialog">
            <form>
                <div class="row form-group">
                    <div class="col-sm-12">
                        <div class="row mb-0">
                            <div class="col-sm-1 pr-0 text-left">   
                                <input type="radio" style="font-size: 18px;">
                                
                            </div>
                            <div class="col-sm-1">
                                <label class="col-form-label">Credito</label>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-sm-1 pr-0 text-left">
                                <input type="radio" name=""  id=""> 
                            </div>
                            <div class="col-sm-1">
                               <label>Contado</label>
                            </div>
                             <div class="col-sm-3">
                                <multiselect
                                            v-model="pago"
                                            track-by="name"
                                            label="name"
                                            :options="pagos"
                                            :allow-empty="false">
                                </multiselect>
                            </div>
                             <div class="col-sm-2">
                                <input type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="1.394.00" 
                                >
                            </div>
                             <div class="col-sm-1">
                               <button class="btn btn-success">
                                +
                               </button>
                            </div>
                        </div>
                        <div class="row mb-2">
                            
                        </div>
                        <div class="row mb-2">
                            
                        </div>
                        <div class="row mb-1">
                            
                                <div class="col-sm-2 pr-0 text-right">
                                    <label style="white-space: nowrap;" class="col-form-label">Medio de Pago:</label>
                                </div>
                                <div class="col-sm-3">
                                    <multiselect
                                                v-model="mediopago"
                                                track-by="name"
                                                label="name"
                                                :options="mediopagos"
                                                :allow-empty="false">
                                    </multiselect>
                                </div>
                                <div class="col-sm-0">
                                    <label></label>
                                </div>
                                <div class="col-sm-0">
                                    <label>importe</label>
                                </div>
                                <div class="col-sm-2">
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-4">
                                <button type="submit" class="btn th-custom-color mr-4">Guardar</button>
                                <button type="button" class="btn btn-secondary mr-4"
                                @click="modalPago = false">Cancelar</button>
                               
                            </div>
                            
                        </div>
                    </div>    
                </div>
               
            </form>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

import axios from 'axios';

export default {
    name: 'TheModalPago',
    components: {
         Multiselect,
    },
    data() {
        return {
            modalPago: false,
               pago: null,
               pagos : [],
               mediopago : null,
               mediopagos : [],
        };
    },
    methods: {
        onlyNumber(event) {
            let tecla = (document.all) ? event.keyCode : event.which;
            if (tecla < 13) {
                return true;
            }
            if (tecla === 13) {
                return event.preventDefault();
            }
            return this.isNumber(String.fromCharCode(tecla)) ? true : event.preventDefault();
        },
        isNumber(cantidad) {
            let expresion = /^\d+$/;
            return expresion.test(cantidad);
        },
    },
    mounted () {
        axios
        .get('')
        .then(response => (this.info = response.data))
        .catch(error => console.log(error))
    }
}
</script>