<template>
    <div>
        <!-- <button
        class="btn btn-dark"
        @click="modalAdUser = true">
            Agregar
        </button> -->
        
                    <button type="button" class="btn btn-success" @click="modalAdUser = true">
                        <i class="nav-icon i-Add-User"></i>
                    </button>
        <b-modal v-model="modalAdUser" title="Nuevo Cliente" size="lg" hide-footer>
            <form>
                <div class="row form-group">
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label class="col-form-label">E-Mail:</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text" class="form-control" autocomplete="off" placeholder="E-Mail">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label class="col-form-label">Nombre Comercial:</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text"
                                class="form-control"
                                autocomplete="off"
                                placeholder="Nombre Comercial"
                                >
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Razon Social:</label>
                            </div>
                            <div class="col-sm-7">
                                <input type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="Razon Social" 
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Telefono:</label>
                            </div>
                            <div class="col-sm-8">
                                <input type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    placeholder="Ingrese un Telefono"
                                >
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Nit:</label>
                            </div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" placeholder="NIT">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                <label style="white-space: nowrap;" class="col-form-label">Codigo:</label>
                                <label>     </label>
                            </div>
                            <div class="col-sm-8">
                                <input type="text" class="form-control" aria-label="Cargar" value=78>
                            </div>
                                      
                        </div>
                        <div class="row mb-4">
                             <div class="col-sm-4 pr-0 text-right">
                                </div>
                             <div class="col-sm-8">
                                <button class="btn btn-success">codigo sugerido</button>
                            </div> 
                        </div>

                    </div>
                    
                    
                </div>
                <div class="row form-group">
                     <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-4 pr-0 text-right">
                                
                            </div>
                            <div class="col-sm-8">
                                 <button type="button" class="btn btn-secondary mr-4"
                                @click="modalAdUser = false">Cancelar</button>
                                <button type="submit" class="btn th-custom-color mr-1">Guardar</button>
                               
                               
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
//import Multiselect from 'vue-multiselect'
//

export default {
    name: 'TheModalAddUser',
    components: {
    },
    data() {
        return {
            modalAdUser: false,
        };
    },
    methods: {
        onlyNumber(event) {
            let tecla = (document.all) ? event.keyCode : event.which;
            if (tecla < 13) {
                return true;
            }
            if (tecla === 13) {
                return event.preventDefault();
            }
            return this.isNumber(String.fromCharCode(tecla)) ? true : event.preventDefault();
        },
        isNumber(cantidad) {
            let expresion = /^\d+$/;
            return expresion.test(cantidad);
        },
    },
}
</script>